.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #54b3e4;
}
.drag-drop-placeholder {
  position: absolute;
  border-radius: 3px;
  background-color: white;
  margin-bottom: 20px;
  border: 2px dashed #54b3e4;
}
.feature-header-sub-title {
  color: #72849a;
  font-size: 14px;
  line-height: 1.5;
}
.line_item_photo .ant-image-error {
  background: #f7f7f8;
  border-radius: 10px;
}
.app-feature-layout {
  padding-left: 325px !important;
  padding-top: 55px !important;
}
.app-settings-menu {
  height: calc(100vh - 70px);
  position: fixed;
  top: 125px;
  padding-top: 25px;
}
.app-settings-layout {
  padding-left: 250px !important;
  padding-top: 55px !important;
}
.app-settings-back-link {
  display: inline-block;
  margin-left: 0px;
}
.app-userpreferences {
  position: relative;
  padding: 0px 15px;
}
.app-nav-avatar {
  left: 0px;
  top: 5px;
  margin: 0px;
  position: absolute;
  bottom: 0px;
  border-radius: 50%;
}
.app-user-info {
  padding-left: 58px;
}
.app-user-info-name {
  color: #33475b;
  font-weight: 600;
  font-size: 16px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.app-user-info-email {
  color: #7c98b6;
  font-size: 12px;
  line-height: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.app-user-info-preferences {
  color: #54b3e4;
}
.app-user-info-preferences:hover {
  text-decoration: underline;
}
.app-user-view-accounts {
  color: #54b3e4;
}
.app-user-view-accounts:hover {
  text-decoration: underline;
}
.app-user-info-preferences {
  font-size: 14px;
  line-height: 32px;
}
.app-auth-logo {
  padding-left: 33px;
  padding-top: 33px;
}
@media only screen and (max-width: 767px) {
  .app-auth-logo {
    padding-left: 0px;
    text-align: center !important;
  }
}
.app-shadow-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17) !important;
}
.app-register-card {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17) !important;
}
.app-auth-header-text {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  margin-top: 0;
  white-space: normal;
  text-align: center;
}
.app-auth-helper-text {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 1em auto 2em;
  text-align: center;
}
.app-gp-header-text {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  margin-top: 0;
  white-space: normal;
}
.app-gp-helper-text {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 1em auto 2em;
}
.app-gp-feature-text {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  color: #455560;
  font-size: 18px;
  font-weight: 400;
}
.app-gp-feature-header {
  font-weight: 500 !important;
  text-transform: uppercase !important;
  font-size: 18px;
}
.app-gp-icon {
  color: #54b3e4;
  width: 20px;
  padding-right: 10px;
}
@media only screen and (min-width: 991px), print {
  .app-border-right {
    border-right: 2px solid #e1e1e1 !important;
  }
}
.app-mascot {
  text-align: center;
}
.app-mascot img {
  max-width: 250px;
  margin-top: 10px;
}
.app-feature-container {
  padding-top: 20px;
}
.app-table .ant-table-thead th.ant-table-cell {
  background-color: #f5f8fa;
}
.app-table .ant-table-thead th.ant-table-cell:hover {
  background-color: #e5f5f8;
}
.app-table-clickable .ant-table-row:hover {
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .app-settings-content {
    margin-top: 14px;
  }
  .app-settings-menu {
    top: 70px;
  }
}
@media screen and (max-width: 767px) {
  .app-settings-menu {
    width: 100% !important;
  }
  .app-settings-menu {
    position: relative;
  }
  .app-settings-layout {
    padding-left: 0 !important;
    padding-top: 55px !important;
  }
  .app-feature-layout {
    padding-left: 0 !important;
    padding-top: 55px !important;
  }
  .fix-mobile-menu {
    flex-direction: column !important;
  }
  .fix-app-setting {
    width: 100% !important;
  }
}
.app-settings-menu {
  overflow: auto;
}
.app-userpreferences-menu .ant-menu-item-selected {
  background-color: #fff !important;
}
.app-userpreferences-menu .ant-menu-item-selected a {
  color: #455560 !important;
}
.app-header-settings-menu .ant-menu-item-selected {
  color: #455560 !important;
}
.app-settings-menu > li > div {
  margin-top: 5px;
}
.app-settings-menu > li > ul > li {
  height: 38px !important;
  line-height: 38px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.app-feature-tabs > div > div.ant-tabs-nav > div.ant-tabs-nav-wrap > div > div.ant-tabs-tab {
  margin-right: 0px !important;
}
.ant-btn {
  border-radius: 3px;
}
.ant-btn-circle {
  border-radius: 50% !important;
}
.app-client-timeline-activity-item .ant-comment-content-author {
  justify-content: space-between !important;
}
.app-auth-container {
  background-color: #f4f4f7;
}
.app-form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.app-form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff6b72;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.feature-header.overlap {
  padding-bottom: 24px;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 2px;
}
.ant-pagination-item,
.ant-pagination-total-text .ant-pagination-item {
  border-radius: 2px;
}
.app-auth-form .ant-form-item {
  margin-bottom: 18px;
}
.app-empty-state {
  padding: 16px;
  box-sizing: border-box;
}
.app-empty-state-body {
  position: relative;
  margin: 32px auto;
}
.app-empty-state-inner {
  max-width: 60%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}
.app-empty-state-header-text {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 400;
  margin-top: 0;
  white-space: normal;
  text-align: center;
}
.app-empty-state-helper-text {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
  margin: auto;
  text-align: center;
}
.app-empty-actions {
  margin: 16px auto 0 auto;
  display: block;
}
.app-map-container {
  height: 100vh;
  width: 100%;
}
.app-full-screen-container {
  background-color: transparent;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: 40px;
}
.app-box-description {
  margin-top: 30px;
  margin-bottom: 30px;
}
.app-header-top-nav-menu ul li {
  padding: 0 15px !important;
}
.app-header-top-nav-menu-item {
  padding: 0 0.5rem !important;
}
.app-fa-menu-item-icon {
  width: 20px;
  font-size: 17px;
}
.app-notification-list-item {
  padding: 4px 0px 4px 0px;
}
.app-notification-collapse-item .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
  padding-right: 0;
}
.app-notification-collapse-item .ant-collapse-item {
  border-bottom: none !important;
}
.app-notification-list-item div div div p {
  margin-bottom: 0px;
}
.ribbon.ribbon-right {
  right: -5px;
}
.ribbon {
  position: absolute;
  top: -5px;
  width: 75px;
  height: 75px;
  z-index: 2;
}
.ribbon.ribbon-primary span {
  background-color: #54b3e4 !important;
}
.ribbon.ribbon-right span {
  right: -23px;
  transform: rotate(45deg);
}
.ribbon span {
  position: absolute;
  top: 15px;
  width: 100px;
  color: #ffffff;
}
.ribbon.ribbon-primary span:before {
  border-left-color: #23a964;
  border-top-color: #23a964;
}
.ribbon span:before {
  left: 0;
}
.ribbon span:before,
.ribbon span:after {
  content: "";
  position: absolute;
  top: 100%;
  z-index: -1;
  border: 3px solid transparent;
}
.ribbon.ribbon-primary span:after {
  border-right-color: #23a964;
  border-top-color: #23a964;
}
.ribbon span:after {
  right: 0;
}
.ribbon span:before,
.ribbon span:after {
  content: "";
  position: absolute;
  top: 100%;
  z-index: -1;
  border: 3px solid transparent;
}
.logo-upload-dragger {
  width: 325px !important;
  height: 100px !important;
}
.logo-upload-dragger-dark-bg {
  background-color: #434343 !important;
}
.logo-upload-dragger-dark-bg p.ant-upload-drag-icon .anticon {
  color: #ffffff !important;
}
.logo-upload-dragger-dark-bg p.ant-upload-hint {
  color: #ffffff !important;
}
.logo-upload-dragger-light-bg {
  background-color: #ffffff !important;
}
.logo-upload-dragger-light-bg p.ant-upload-drag-icon .anticon {
  color: #2d2d2d !important;
}
.logo-upload-dragger-light-bg p.ant-upload-hint {
  color: #2d2d2d !important;
}
.test-width {
  width: 260px !important;
}
.app-uploader-holder {
  width: 260px !important;
  height: 144px !important;
  border-radius: 0.625rem;
  padding: 0 1rem;
  position: relative;
}
.app-uploader-light-bg {
  background-color: #ededed !important;
  border: 2px dashed #ededed;
}
.app-uploader-dark-bg {
  background-color: #434343 !important;
  border: 2px dashed #434343;
}
.app-uploader-holder img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.app-uploader-holder-icon {
  padding-top: 25px;
  text-align: center;
}
.app-uploader-holder-icon svg {
  height: 40px;
}
.app-form-change-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
  height: 0;
  padding: 0;
}
.ant-checkbox {
  background: rgba(235, 237, 242, 0.4);
}
.ant-input {
  background: rgba(235, 237, 242, 0.4);
}
.ant-input-affix-wrapper {
  background: rgba(235, 237, 242, 0.4);
}
.ant-input::placeholder {
  color: #495057;
}
.ant-select-selector {
  background: rgba(235, 237, 242, 0.4) !important;
}
.ant-input-number-input {
  background: rgba(235, 237, 242, 0.4) !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: #495057;
}
.ant-input-number-input::placeholder {
  color: #495057;
}
.ant-upload.ant-upload-drag {
  min-height: 100px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 4px;
}
.app-link {
  color: #54b3e4;
  cursor: pointer;
}
.app-link:hover {
  color: #3ea9e1;
}
.hide {
  display: none !important;
}
.app-biz-hours .ant-select {
  width: 45%;
}
.app-biz-hours span {
  width: 10%;
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.app-account-logo-icon {
  color: #54b3e4;
  font-size: 20px;
}
.permission-item {
  border-bottom: 1px solid #e6ebf1;
  padding-top: 15px;
  padding-bottom: 15px;
}
.app-modal-danger .ant-modal-header {
  background-color: #ff6b72;
}
.app-modal-danger .ant-modal-title {
  color: #fff;
}
.app-modal-danger .ant-modal-close {
  color: #fff;
}
.app-modal-warning .ant-modal-header {
  background-color: #ffc542;
}
.app-modal-warning .ant-modal-title {
  color: #fff;
}
.app-modal-warning .ant-btn-warning {
  background-color: #ffc542;
  color: #fff;
}
.app-modal-warning .ant-modal-close {
  color: #fff;
}
.app-table-empty {
  margin: 30px auto;
  max-width: 815px;
  padding-left: 56px;
  width: 100%;
}
.app-client-status-color-picker {
  border: none !important;
  box-shadow: none !important;
}
.app-client-status-color-picker div {
  padding: 0 !important;
}
.app-job-status-color-picker {
  border: none !important;
  box-shadow: none !important;
}
.app-job-status-color-picker div {
  padding: 0 !important;
}
.app-form-field-list-item {
  height: auto;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #edf2f9;
}
.app-form-field-list-item-icon {
  font-size: 32px;
}
.app-form-field-list-item-desc {
  font-size: 12px;
  line-height: 18px;
}
.app-form-field-list-item:hover {
  border: 1px solid #54b3e4;
  border-color: #74dae6;
  cursor: pointer !important;
  box-shadow: 0 0 8px 1px rgb(35 169 1.66666667%);
}
.app-request-form-fields {
  border-top: 1px solid #e6ebf1;
  padding-top: 10px;
  padding-bottom: 10px;
}
.app-request-item-area {
  padding-top: 10px;
  padding-bottom: 10px;
}
.app-request-item-area:hover {
  background-color: #e5f7ed;
}
.app-radio-percentage-flat {
  width: 100%;
}
.app-radio-percentage-flat .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}
.app-quote-totals {
  width: 100%;
}
.app-quote-totals .subfield {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  font-size: 14px;
  color: #4d4d4d;
  border-top: 2px solid #eee;
  margin-top: 10px;
  padding-top: 9px;
}
.subitem-action {
  padding-top: 9px;
  padding-bottom: 9px;
}
.app-quote-subtotal {
  color: red;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
}
.app-quote-totals .subfield-item {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  font-size: 14px;
  color: #4d4d4d;
}
.app-totals {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.app-totals .subfield {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  min-width: 350px;
  font-size: 14px;
  color: #4d4d4d;
  border-bottom: 2px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 9px;
}
.app-totals .taxes {
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  min-width: 350px;
  font-size: 14px;
  color: #4d4d4d;
  margin-bottom: 10px;
  padding-bottom: 9px;
  border-bottom: none !important;
}
.fulltotal {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  min-width: 350px;
  align-self: flex-end;
  font-size: 20px;
  font-weight: 700;
}
.app-request-input-item input,
textarea {
  cursor: grab !important;
}
.app-request-input-item .ant-switch-disabled {
  cursor: grab !important;
}
.app-request-input-item .ant-checkbox-disabled {
  cursor: grab !important;
}
.app-request-input-item button:disabled {
  cursor: grab !important;
}
.app-timeline-event .ant-card-body {
  padding: 10px !important;
}
.app-timeline-event-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #33475b !important;
  white-space: nowrap;
}
.app-timeline-event-datetime {
  font-size: 12px;
  line-height: 18px;
  color: #516f90 !important;
  white-space: nowrap;
}
.app-timeline-event-message {
  font-weight: 400;
  font-size: 14px;
  color: #33475b;
  line-height: 24px;
}
.app-timeline-month {
  font-weight: 400;
  font-size: 16px;
  color: #33475b;
  line-height: 24px;
}
.app-timeline-event-inner {
  display: flex;
  padding: 8px 0;
}
.app-timeline-event-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
}
.app-timeline-event-details {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.app-note-tag {
  line-height: 17px;
  padding: 0px 5px;
}
.p-y-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.cursor-text input,
textarea {
  cursor: text !important;
}
.cursor-text .ant-switch-disabled {
  cursor: text !important;
}
.cursor-text .ant-checkbox-disabled {
  cursor: text !important;
}
.cursor-text button:disabled {
  cursor: text !important;
}
.request-header-bg {
  min-height: 400px;
}
.request-form-body {
  transform: translateY(-300px);
}
.componeyname {
  color: #fff !important;
}
.request-form-body .container {
  background-color: #fff;
  padding: 0 15px;
  border-radius: 5px;
}
.request-footer {
  transform: translateY(-250px);
}
.request-footer button {
  max-width: 300px;
}
@media only screen and (max-width: 767px) {
  .request-footer button {
    max-width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .request-form-body .container {
    margin: 0 10px;
  }
}
.power-logo {
  margin-top: 30px !important;
  width: 30%;
}
.header-text {
  padding-top: 20px !important;
}
.custom-switch {
  padding: 20px;
}
.custom-switch .ant-switch-handle {
  top: 50%;
  left: 2px;
  width: 33px;
  height: 32px;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
}
.custom-switch.ant-switch-checked .ant-switch-handle {
  left: calc((100% - 33px) - 2px);
}
.amount-box {
  border: 4px solid #e0dddd;
  border-radius: 8px;
  padding: 5px 7px;
}
.quotes-content {
  padding: 0px 10px;
}
.ant-btn-danger:hover {
  background-color: #fa6c73 !important;
  color: white !important;
}
.attachment-wrapper {
  background-color: #fafafb00 !important;
}
.line-item {
  display: flex;
  justify-content: space-between;
}
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  align-self: center;
  background: #c2c1c1;
}
.attachment {
  display: flex;
  justify-content: space-between;
  padding: 0px 2px;
}
.details {
  display: flex;
  justify-content: space-between;
}
.content-box {
  padding-top: 8px;
  padding-left: 25px;
}
.custom-badge {
  padding: 5px 25px;
  background-color: gray;
  border-radius: 5px;
  color: white;
}
.icon-wrapper {
  align-self: center;
}
.download-icon {
  font-size: 30px;
  margin-right: 16px;
  align-self: center;
}
.signatureCanvas {
  border: 2px solid gray;
}
.text-orange {
  color: orange;
}
.avatar-wrapper {
  max-width: 50px;
  margin-right: 29px;
}
.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  align-self: center;
  background: #c2c1c1;
}
@media only screen and (max-width: 991px) {
  .quotes-content {
    padding: 0px;
  }
}
.mr-1 {
  margin-right: 1em;
}
